import {
  GET_COMPETITIONS_DATA,
  SYNC_COMPETITIONS_DATA,
  SYNC_COMPETITIONS_DATA_SW,
  FETCH_COMPETITIONS_DATA,
  GET_COMPETITIONS_TEAMS_DATA,
  SET_COMPETITIONS_TEAMS_LOGO,
  GET_COMPETITIONS_DETAILS,
  SET_COMPETITIONS_TEAMS_TOP,
  SET_COMPETITIONS_BROADCAST,
  FETCH_COMPETITIONS_SEASON,
  TOP_COMPETITIONS_DATA,
  GET_TOP_COMPETITIONS,
  FETCH_COMPETITIONS_DETAILS,
  SELECT_SPORT,
  TOGGLE_SIDEBAR_COLLAPSED,
  GET_SINGLE_COMPETITION_ODDS,
  SET_COMPETITION_FILTERS,
} from '../../constants/ActionTypes';
import { getTeams } from './Teams';
import { fetchError, fetchStart, fetchSuccess } from './Common';
import axios from '../../services/auth/jwt/config';

export const getCompetitions = (selectedSport, page, rowsPerPage, searchQuery, sortType) => {
  return dispatch => {
    dispatch(fetchStart());

    let url = `/competitions?offset=${page * rowsPerPage}&limit=${rowsPerPage}`;
    if (selectedSport) {
      url += `&sportsIds=${selectedSport}`;
    }
    if (searchQuery) {
      url += `&name=${searchQuery}`;
    }
    if (sortType) {
      url += `&sortBy=${sortType.field}&sortOrder=${sortType.order}`;
    }

    axios
      .get(url)
      .then(data => {
        dispatch(fetchSuccess());
        dispatch({ type: GET_COMPETITIONS_DATA, payload: data });
      })
      .catch(error => {
        if (error.response?.status !== 401) {
          dispatch(fetchError('Something went wrong'));
        }
      });
  };
};

export const getTopCompetitions = (selectedSport, page, rowsPerPage, searchQuery) => {
  return dispatch => {
    dispatch(fetchStart());
    if (selectedSport) {
      axios
        .get(
          `/competitions/top-competitions?sportsIds=${selectedSport}&offset=${page *
            rowsPerPage}&limit=${rowsPerPage}&name=${searchQuery}`,
        )
        .then(data => {
          dispatch(fetchSuccess());
          dispatch({ type: GET_TOP_COMPETITIONS, payload: data });
        })
        .catch(error => {
          if (error.response?.status !== 401) {
            dispatch(fetchError('Something went wrong'));
          }
        });
    }
  };
};

export const getCompetitionsDetails = id => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .get('/competitions/' + id)
      .then(data => {
        dispatch(fetchSuccess());
        dispatch({ type: GET_COMPETITIONS_DETAILS, payload: data });
      })
      .catch(error => {
        if (error.response?.status !== 401) {
          dispatch(fetchError('Something went wrong'));
        }
      });
  };
};

export const getSingleCompetitionOdds = competitionId => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .get('/odds/competition/' + competitionId)
      .then(data => {
        dispatch(fetchSuccess());
        dispatch({ type: GET_SINGLE_COMPETITION_ODDS, payload: data });
      })
      .catch(error => {
        if (error.response?.status !== 401) {
          dispatch(fetchError('Something went wrong'));
        }
      });
  };
};

export const getCompetitionsTeams = id => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .get('/teams/' + id)
      .then(data => {
        dispatch(fetchSuccess());
        dispatch({ type: GET_COMPETITIONS_TEAMS_DATA, payload: data });
      })
      .catch(error => {
        if (error.response?.status !== 401) {
          dispatch(fetchError('Something went wrong'));
        }
      });
  };
};

export const syncCompetitions = (id, sync) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .post('/competitions/change-sync-status', {
        competitionId: id,
        syncStatus: sync,
      })
      .then(data => {
        if (data.status === 200 || data.status === 201) {
          dispatch(fetchSuccess());
          dispatch({ type: SYNC_COMPETITIONS_DATA, payload: data });
          dispatch(fetchError(`Sync success`));
        }
      })
      .catch(error => {
        if (error.response?.status !== 401) {
          dispatch(fetchError('Something went wrong'));
        }
      });
  };
};

export const syncF1Competitions = (id, sync) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .post('/competitions/change-is-f1-competition', {
        competitionId: id,
        isF1Competition: sync,
      })
      .then(data => {
        if (data.status === 200 || data.status === 201) {
          dispatch(fetchSuccess());
          dispatch({ type: SYNC_COMPETITIONS_DATA, payload: data });
          dispatch(fetchError(`Sync success`));
        }
      })
      .catch(error => {
        if (error.response?.status !== 401) {
          dispatch(fetchError('Something went wrong'));
        }
      });
  };
};

export const syncCompetitionsSportWorld = (id, sync) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .post('/competitions/change-sync-status/sportworld', {
        competitionId: id,
        syncStatus: sync,
      })
      .then(data => {
        if (data.status === 200 || data.status === 201) {
          dispatch(fetchSuccess());
          dispatch({ type: SYNC_COMPETITIONS_DATA_SW, payload: data });
          dispatch(fetchError(`Sync success`));
        }
      })
      .catch(error => {
        if (error.response?.status !== 401) {
          dispatch(fetchError('Something went wrong'));
        }
      });
  };
};

export const topCompetitions = (id, top) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .post('/competitions/top-competitions/change-status', {
        competitionId: id,
        status: top,
      })
      .then(data => {
        if (data.status === 200 || data.status === 201) {
          dispatch(fetchSuccess());
          dispatch({ type: TOP_COMPETITIONS_DATA, payload: data });
          dispatch(fetchError(`Top Competition changed`));
        }
      })
      .catch(error => {
        if (error.response?.status !== 401) {
          dispatch(fetchError('Something went wrong'));
        }
      });
  };
};

export const addBroadcastCompetition = (id, body) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .post('/competitions/change-broadcasters/' + id, body)
      .then(data => {
        dispatch(getCompetitionsDetails(id));
        if (data.status === 200 || data.status === 201) {
          dispatch(fetchSuccess());
          dispatch({ type: SET_COMPETITIONS_BROADCAST, payload: data });
          dispatch(fetchError(`Broadcast add success`));
        }
      })
      .catch(error => {
        if (error.response?.status !== 401) {
          dispatch(fetchError('Something went wrong'));
        }
      });
  };
};

export const fetchCompetitions = () => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .post('v2/competitions/fetch')
      .then(data => {
        if (data.status === 200 || data.status === 201) {
          dispatch(fetchSuccess());
          dispatch({ type: FETCH_COMPETITIONS_DATA, payload: data });
          dispatch(fetchError(`Fetch success`));
        }
      })
      .catch(error => {
        if (error.response?.status !== 401) {
          dispatch(fetchError('Something went wrong'));
        }
      });
  };
};

export const fetchCompetitionsDetails = (back, future, body, days) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .post('v2/matches/fetch-all-details', {
        startDate: back,
        endDate: future,
        days: days,
        competitionId: body.competitionId,
      })
      .then(data => {
        if (data.status === 200 || data.status === 201) {
          dispatch(fetchSuccess());
          dispatch({ type: FETCH_COMPETITIONS_DETAILS, payload: data });
          dispatch(fetchError(`Fetch success`));
        }
      })
      .catch(error => {
        if (error.response.status === 502) {
          dispatch(fetchError('Fetch details started with success '));
        } else {
          dispatch(fetchError(error.response.data.message));
        }
      });
  };
};

export const fetchAllCompetitionsDetails = body => {
  let futureDate = new Date();
  futureDate.setDate(futureDate.getDate() + 7);
  let presentDate = new Date();
  // pastDate.setDate(pastDate.getDate() - 7);

  presentDate = presentDate.toISOString();
  futureDate = futureDate.toISOString();

  return dispatch => {
    dispatch(fetchStart());
    axios
      .post('v2/matches/fetch-all-details', {
        startDate: presentDate,
        endDate: futureDate,
        days: 7,
      })
      .then(data => {
        if (data.status === 200 || data.status === 201) {
          dispatch(fetchSuccess());
          dispatch(fetchError(`Fetch success`));
        }
      })
      .catch(error => {
        if (error.response.status === 502) {
          dispatch(fetchError('Fetch details started with success '));
        } else {
          dispatch(fetchError(error.response.data.message));
        }
      });
  };
};

export const fetchCompetitionsSeason = body => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .post('v2/matches/fetch-seasons', { competitions: ['' + body + ''] })
      .then(data => {
        if (data.status === 200 || data.status === 201) {
          dispatch(fetchSuccess());
          dispatch({ type: FETCH_COMPETITIONS_SEASON, payload: data });
          dispatch(fetchError(`Fetch success`));
        }
      })
      .catch(error => {
        if (error.response?.status !== 401) {
          dispatch(fetchError(error.response.data.message));
        }
      });
  };
};

export const setCompetitionsTeamLogo = newLogo => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .post('/teams/add-team-logo', newLogo)
      .then(data => {
        dispatch(getTeams());
        dispatch(fetchError(`Logo change success`));
        dispatch({ type: SET_COMPETITIONS_TEAMS_LOGO, payload: data });
      })
      .catch(error => {
        if (error.response?.status !== 401) {
          dispatch(fetchError('Something went wrong'));
        }
      });
  };
};

export const setCompetitionsTeamTop = status => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .post('/teams/top-teams/change-status', status)
      .then(data => {
        if (data.status === 200 || data.status === 201) {
          dispatch(fetchSuccess());
          dispatch({ type: SET_COMPETITIONS_TEAMS_TOP, payload: data });
          dispatch(fetchError(`Top participant set`));
        }
      })
      .catch(error => {
        if (error.response?.status !== 401) {
          dispatch(fetchError('Something went wrong'));
        }
      });
  };
};

export const selectSport = sport => {
  return {
    type: SELECT_SPORT,
    payload: sport,
  };
};

export const toggleExpandSidebar = value => {
  return dispatch => {
    dispatch({
      type: TOGGLE_SIDEBAR_COLLAPSED,
      payload: value,
    });
  };
};

export const setCompetitionFilters = sortType => {
  return {
    type: SET_COMPETITION_FILTERS,
    payload: sortType,
  };
};

export const setCompetitionAsRecommended = (competitionId, recommendedStatus) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .post('competitions/change-recommended', {
        competitionId,
        recommendedStatus,
      })
      .then(data => {
        if (data.status === 200 || data.status === 201) {
          dispatch(fetchSuccess());
          dispatch({ type: SYNC_COMPETITIONS_DATA, payload: data });
          dispatch(fetchError(`Popular status has been changed`));
        }
      })
      .catch(error => {
        if (error.response?.status !== 401) {
          dispatch(fetchError('Something went wrong'));
        }
      });
  };
};
